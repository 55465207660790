/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSA_METHOD_OF_REDRESS = "EMAIL" | "NONE" | "URL" | "%future added value";
export type GeneralConfigContainer_settings = {
    readonly flattenReplies: boolean;
    readonly locale: string;
    readonly dsa: {
        readonly enabled: boolean;
        readonly methodOfRedress: {
            readonly method: DSA_METHOD_OF_REDRESS;
            readonly url: string | null;
            readonly email: string | null;
        };
    };
    readonly communityGuidelines: {
        readonly enabled: boolean;
        readonly content: string | null;
    };
    readonly charCount: {
        readonly enabled: boolean;
        readonly min: number | null;
        readonly max: number | null;
    };
    readonly editCommentWindowLength: number;
    readonly closeCommenting: {
        readonly message: string;
        readonly auto: boolean;
        readonly timeout: number;
    };
    readonly disableCommenting: {
        readonly enabled: boolean;
        readonly message: string;
    };
    readonly featuredBy: boolean | null;
    readonly reaction: {
        readonly label: string;
        readonly labelActive: string;
        readonly sortLabel: string;
        readonly icon: string;
        readonly iconActive: string | null;
    };
    readonly badges: {
        readonly staffLabel: string;
        readonly adminLabel: string;
        readonly moderatorLabel: string;
        readonly memberLabel: string;
    };
    readonly topCommenter: {
        readonly enabled: boolean | null;
    } | null;
    readonly flairBadges: {
        readonly flairBadgesEnabled: boolean | null;
    } | null;
    readonly rte: {
        readonly enabled: boolean;
        readonly strikethrough: boolean;
        readonly spoiler: boolean;
    };
    readonly media: {
        readonly twitter: {
            readonly enabled: boolean;
        };
        readonly youtube: {
            readonly enabled: boolean;
        };
        readonly giphy: {
            readonly enabled: boolean;
            readonly maxRating: string | null;
            readonly key: string | null;
        };
    };
    readonly memberBios: boolean;
    readonly " $fragmentRefs": FragmentRefs<"AnnouncementConfigContainer_settings" | "DSAConfigContainer_settings" | "FlairBadgeConfigContainer_settings" | "ReactionConfigContainer_settings">;
    readonly " $refType": "GeneralConfigContainer_settings";
};
export type GeneralConfigContainer_settings$data = GeneralConfigContainer_settings;
export type GeneralConfigContainer_settings$key = {
    readonly " $data"?: GeneralConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"GeneralConfigContainer_settings">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
    } as any), v2 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "GeneralConfigContainer_settings",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "flattenReplies",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locale",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "DSAConfiguration",
                "kind": "LinkedField",
                "name": "dsa",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DSAMethodOfRedressConfiguration",
                        "kind": "LinkedField",
                        "name": "methodOfRedress",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "method",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommunityGuidelines",
                "kind": "LinkedField",
                "name": "communityGuidelines",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CharCount",
                "kind": "LinkedField",
                "name": "charCount",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "min",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editCommentWindowLength",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CloseCommenting",
                "kind": "LinkedField",
                "name": "closeCommenting",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "auto",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeout",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "DisableCommenting",
                "kind": "LinkedField",
                "name": "disableCommenting",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featuredBy",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ReactionConfiguration",
                "kind": "LinkedField",
                "name": "reaction",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "labelActive",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortLabel",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "icon",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconActive",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "BadgeConfiguration",
                "kind": "LinkedField",
                "name": "badges",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "staffLabel",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adminLabel",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "moderatorLabel",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "memberLabel",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "TopCommenterConfiguration",
                "kind": "LinkedField",
                "name": "topCommenter",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "FlairBadgeConfiguration",
                "kind": "LinkedField",
                "name": "flairBadges",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flairBadgesEnabled",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "RTEConfiguration",
                "kind": "LinkedField",
                "name": "rte",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strikethrough",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spoiler",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "MediaConfiguration",
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TwitterMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "twitter",
                        "plural": false,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "YouTubeMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "youtube",
                        "plural": false,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "GiphyMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "giphy",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxRating",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "key",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "memberBios",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AnnouncementConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DSAConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FlairBadgeConfigContainer_settings"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ReactionConfigContainer_settings"
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7bcd5ea9455a3395cbfe9b721bfe6d93';
export default node;
