/* tslint:disable */
/* eslint-disable */
/* @relayHash af9d10f17861cab2636bb0390295ad7d */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type SectionFilter = {
    name?: string | null;
};
export type QueueRouteReportedQueryVariables = {
    storyID?: string | null;
    siteID?: string | null;
    section?: SectionFilter | null;
    initialOrderBy?: COMMENT_SORT | null;
};
export type QueueRouteReportedQueryResponse = {
    readonly moderationQueues: {
        readonly reported: {
            readonly " $fragmentRefs": FragmentRefs<"QueueRoute_queue">;
        };
    };
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"QueueRoute_settings">;
    };
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"QueueRoute_viewer">;
    } | null;
};
export type QueueRouteReportedQuery = {
    readonly response: QueueRouteReportedQueryResponse;
    readonly variables: QueueRouteReportedQueryVariables;
};



/*
query QueueRouteReportedQuery(
  $storyID: ID
  $siteID: ID
  $section: SectionFilter
  $initialOrderBy: COMMENT_SORT
) {
  moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
    reported {
      ...QueueRoute_queue_30VPVr
      id
    }
  }
  settings {
    ...QueueRoute_settings
    id
  }
  viewer {
    ...QueueRoute_viewer
    id
  }
}

fragment AutomatedActionsContainer_comment on Comment {
  revision {
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment AutomatedActionsContainer_settings on Settings {
  integrations {
    perspective {
      threshold
    }
    external {
      phases {
        name
        id
      }
    }
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      suspension {
        active
      }
      premod {
        active
      }
      ban {
        active
      }
    }
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment DecisionDetailsContainer_comment on Comment {
  id
  statusHistory(first: 1) {
    edges {
      node {
        createdAt
        rejectionReason {
          code
          legalGrounds
          detailedExplanation
          customReason
        }
        id
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
      id
    }
  }
  illegalContent {
    nodes {
      flagger {
        username
        id
      }
      additionalDetails
      reportID
      id
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment LinkDetailsContainer_settings on Settings {
  organization {
    url
  }
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  status
  tags {
    code
  }
  author {
    premoderatedBecauseOfEmailAt
    id
  }
  revision {
    actionCounts {
      illegal {
        total
      }
      flag {
        reasons {
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_BIO
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_REPEAT_POST
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
        }
      }
    }
    metadata {
      wordList {
        timedOut
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment MarkersContainer_settings on Settings {
  ...ModerateCardDetailsContainer_settings
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  site {
    id
    name
  }
  author {
    id
    email
    username
    status {
      current
      ban {
        active
        sites {
          id
          name
        }
      }
    }
    role
  }
  createdAt
  body
  rating
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
        }
      }
    }
    metadata {
      wordList {
        bannedWords {
          value
          index
          length
        }
        suspectWords {
          value
          index
          length
        }
      }
    }
    id
  }
  tags {
    code
  }
  status
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  canModerate
  story {
    id
    metadata {
      title
    }
    settings {
      mode
    }
    isArchived
    isArchiving
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
  ...MediaContainer_comment
}

fragment ModerateCardContainer_settings on Settings {
  locale
  wordList {
    banned
    suspect
  }
  emailDomainModeration {
    domain
    newUserModeration
    id
  }
  multisite
  ...MarkersContainer_settings
}

fragment ModerateCardContainer_viewer on User {
  id
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  id
  status
  tags {
    code
  }
  editing {
    edited
  }
  statusHistory(first: 1) {
    edges {
      node {
        rejectionReason {
          code
        }
        id
      }
    }
  }
  revision {
    actionCounts {
      illegal {
        total
      }
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
          COMMENT_REPORTED_BIO
        }
      }
      reaction {
        total
      }
    }
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
  ...AutomatedActionsContainer_comment
  ...DecisionDetailsContainer_comment
}

fragment ModerateCardDetailsContainer_settings on Settings {
  ...LinkDetailsContainer_settings
  ...AutomatedActionsContainer_settings
}

fragment ModeratedByContainer_comment on Comment {
  id
  status
  statusHistory(first: 1) {
    edges {
      node {
        status
        moderator {
          id
          username
        }
        id
      }
    }
  }
  revision {
    metadata {
      externalModeration {
        name
        result {
          status
        }
      }
    }
    id
  }
}

fragment QueueRoute_queue_30VPVr on ModerationQueue {
  count
  comments(first: 5, orderBy: $initialOrderBy) {
    edges {
      node {
        id
        site {
          id
        }
        author {
          id
        }
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment QueueRoute_settings on Settings {
  ...ModerateCardContainer_settings
}

fragment QueueRoute_viewer on User {
  ...ModerateCardContainer_viewer
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "initialOrderBy"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "section",
            "variableName": "section"
        } as any),
        ({
            "kind": "Variable",
            "name": "siteID",
            "variableName": "siteID"
        } as any),
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v5 = ({
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "initialOrderBy"
    } as any), v6 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 5
        } as any),
        (v5 /*: any*/)
    ], v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v9 = [
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v10 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sites",
        "plural": true,
        "selections": (v9 /*: any*/),
        "storageKey": null
    } as any), v15 = [
        (v13 /*: any*/)
    ], v16 = ({
        "alias": null,
        "args": null,
        "concreteType": "UserStatus",
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "current",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "BanStatus",
                "kind": "LinkedField",
                "name": "ban",
                "plural": false,
                "selections": [
                    (v13 /*: any*/),
                    (v14 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "SuspensionStatus",
                "kind": "LinkedField",
                "name": "suspension",
                "plural": false,
                "selections": (v15 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "PremodStatus",
                "kind": "LinkedField",
                "name": "premod",
                "plural": false,
                "selections": (v15 /*: any*/),
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "premoderatedBecauseOfEmailAt",
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commentsRejectedOnSites",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allCommentsRejected",
        "storageKey": null
    } as any), v21 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v22 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v23 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    } as any), v24 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
        } as any)
    ], v25 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "length",
            "storageKey": null
        } as any)
    ], v26 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v27 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
    } as any), v28 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v29 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v30 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v31 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v32 = [
        (v29 /*: any*/)
    ], v33 = ({
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
            (v28 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    (v29 /*: any*/),
                    (v30 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                    },
                    (v31 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "video",
                        "storageKey": null
                    }
                ],
                "type": "GiphyMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v32 /*: any*/),
                "type": "TwitterMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v29 /*: any*/),
                    (v31 /*: any*/),
                    (v30 /*: any*/)
                ],
                "type": "YouTubeMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v32 /*: any*/),
                "type": "ExternalMedia",
                "abstractKey": null
            }
        ],
        "storageKey": null
    } as any), v34 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revision",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ActionCounts",
                "kind": "LinkedField",
                "name": "actionCounts",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FlagActionCounts",
                        "kind": "LinkedField",
                        "name": "flag",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "FlagReasonActionCounts",
                                "kind": "LinkedField",
                                "name": "reasons",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_BANNED_WORD",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_OFFENSIVE",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_ABUSIVE",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_SPAM",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_OTHER",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_TOXIC",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_SPAM",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_BIO",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_REPEAT_POST",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_LINKS",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "IllegalActionCounts",
                        "kind": "LinkedField",
                        "name": "illegal",
                        "plural": false,
                        "selections": (v24 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReactionActionCounts",
                        "kind": "LinkedField",
                        "name": "reaction",
                        "plural": false,
                        "selections": (v24 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevisionMetadata",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionWordListMetadata",
                        "kind": "LinkedField",
                        "name": "wordList",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "WordlistMatch",
                                "kind": "LinkedField",
                                "name": "bannedWords",
                                "plural": true,
                                "selections": (v25 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "WordlistMatch",
                                "kind": "LinkedField",
                                "name": "suspectWords",
                                "plural": true,
                                "selections": (v25 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timedOut",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionPerspectiveMetadata",
                        "kind": "LinkedField",
                        "name": "perspective",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "score",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                        "kind": "LinkedField",
                        "name": "externalModeration",
                        "plural": true,
                        "selections": [
                            (v8 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExternalModerationPhaseResult",
                                "kind": "LinkedField",
                                "name": "result",
                                "plural": false,
                                "selections": [
                                    (v26 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "tags",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExternalModerationPhaseAction",
                                        "kind": "LinkedField",
                                        "name": "actions",
                                        "plural": true,
                                        "selections": [
                                            (v27 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "analyzedAt",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v7 /*: any*/),
            (v33 /*: any*/)
        ],
        "storageKey": null
    } as any), v35 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
    } as any), v36 = ({
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
            (v35 /*: any*/)
        ],
        "storageKey": null
    } as any), v37 = ({
        "alias": null,
        "args": null,
        "concreteType": "EditInfo",
        "kind": "LinkedField",
        "name": "editing",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "edited",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v38 = [
        (v7 /*: any*/),
        (v12 /*: any*/)
    ], v39 = ({
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": (v38 /*: any*/),
                "storageKey": null
            },
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any), v40 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canModerate",
        "storageKey": null
    } as any), v41 = ({
        "alias": null,
        "args": null,
        "concreteType": "Story",
        "kind": "LinkedField",
        "name": "story",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "StoryMetadata",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                    (v30 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "StorySettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchiving",
                "storageKey": null
            },
            (v29 /*: any*/)
        ],
        "storageKey": null
    } as any), v42 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permalink",
        "storageKey": null
    } as any), v43 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
    } as any), v44 = ({
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "first",
                "value": 1
            }
        ],
        "concreteType": "CommentModerationActionConnection",
        "kind": "LinkedField",
        "name": "statusHistory",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentModerationActionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentModerationAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RejectionReason",
                                "kind": "LinkedField",
                                "name": "rejectionReason",
                                "plural": false,
                                "selections": [
                                    (v35 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "legalGrounds",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "detailedExplanation",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "customReason",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            (v7 /*: any*/),
                            (v21 /*: any*/),
                            (v26 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "moderator",
                                "plural": false,
                                "selections": (v38 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "statusHistory(first:1)"
    } as any), v45 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "flagger",
        "plural": false,
        "selections": [
            (v12 /*: any*/),
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any), v46 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalDetails",
        "storageKey": null
    } as any), v47 = ({
        "alias": null,
        "args": null,
        "concreteType": "FlagsConnection",
        "kind": "LinkedField",
        "name": "flags",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Flag",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    (v45 /*: any*/),
                    (v27 /*: any*/),
                    (v46 /*: any*/),
                    (v7 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v48 = ({
        "alias": null,
        "args": null,
        "concreteType": "FlagsConnection",
        "kind": "LinkedField",
        "name": "illegalContent",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Flag",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    (v45 /*: any*/),
                    (v46 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportID",
                        "storageKey": null
                    },
                    (v7 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v49 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revisionHistory",
        "plural": true,
        "selections": [
            (v7 /*: any*/),
            (v22 /*: any*/),
            (v21 /*: any*/),
            (v33 /*: any*/)
        ],
        "storageKey": null
    } as any), v50 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewerDidModerate",
        "storageKey": null
    } as any), v51 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusLiveUpdated",
        "storageKey": null
    } as any), v52 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enteredLive",
        "storageKey": null
    } as any), v53 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "QueueRouteReportedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "reported",
                            "plural": false,
                            "selections": [
                                {
                                    "args": [
                                        (v5 /*: any*/)
                                    ],
                                    "kind": "FragmentSpread",
                                    "name": "QueueRoute_queue"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "QueueRoute_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "QueueRoute_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v3 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "QueueRouteReportedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "reported",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "count",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v6 /*: any*/),
                                    "concreteType": "CommentsConnection",
                                    "kind": "LinkedField",
                                    "name": "comments",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v10 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "author",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v16 /*: any*/),
                                                                (v17 /*: any*/),
                                                                (v18 /*: any*/),
                                                                {
                                                                    "kind": "ClientExtension",
                                                                    "selections": [
                                                                        (v19 /*: any*/),
                                                                        (v20 /*: any*/)
                                                                    ]
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v21 /*: any*/),
                                                        (v22 /*: any*/),
                                                        (v23 /*: any*/),
                                                        (v34 /*: any*/),
                                                        (v36 /*: any*/),
                                                        (v26 /*: any*/),
                                                        (v37 /*: any*/),
                                                        (v39 /*: any*/),
                                                        (v40 /*: any*/),
                                                        (v41 /*: any*/),
                                                        (v42 /*: any*/),
                                                        (v43 /*: any*/),
                                                        (v44 /*: any*/),
                                                        (v47 /*: any*/),
                                                        (v48 /*: any*/),
                                                        (v49 /*: any*/),
                                                        (v28 /*: any*/),
                                                        {
                                                            "kind": "ClientExtension",
                                                            "selections": [
                                                                (v50 /*: any*/),
                                                                (v51 /*: any*/),
                                                                (v52 /*: any*/)
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v53 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ClientExtension",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "viewNewEdges",
                                                    "plural": true,
                                                    "selections": [
                                                        (v53 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Comment",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v10 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "author",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v19 /*: any*/),
                                                                        (v20 /*: any*/),
                                                                        (v7 /*: any*/),
                                                                        (v11 /*: any*/),
                                                                        (v12 /*: any*/),
                                                                        (v16 /*: any*/),
                                                                        (v17 /*: any*/),
                                                                        (v18 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v51 /*: any*/),
                                                                (v21 /*: any*/),
                                                                (v22 /*: any*/),
                                                                (v23 /*: any*/),
                                                                (v34 /*: any*/),
                                                                (v36 /*: any*/),
                                                                (v26 /*: any*/),
                                                                (v37 /*: any*/),
                                                                (v39 /*: any*/),
                                                                (v40 /*: any*/),
                                                                (v41 /*: any*/),
                                                                (v42 /*: any*/),
                                                                (v52 /*: any*/),
                                                                (v43 /*: any*/),
                                                                (v44 /*: any*/),
                                                                (v47 /*: any*/),
                                                                (v48 /*: any*/),
                                                                (v49 /*: any*/),
                                                                (v50 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v6 /*: any*/),
                                    "filters": [
                                        "orderBy"
                                    ],
                                    "handle": "connection",
                                    "key": "Queue_comments",
                                    "kind": "LinkedHandle",
                                    "name": "comments"
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WordList",
                            "kind": "LinkedField",
                            "name": "wordList",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "banned",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "suspect",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailDomain",
                            "kind": "LinkedField",
                            "name": "emailDomainModeration",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "domain",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "newUserModeration",
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": (v32 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "kind": "LinkedField",
                            "name": "integrations",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PerspectiveExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "perspective",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "threshold",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CustomExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "external",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalModerationPhase",
                                            "kind": "LinkedField",
                                            "name": "phases",
                                            "plural": true,
                                            "selections": [
                                                (v8 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v17 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                (v14 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "af9d10f17861cab2636bb0390295ad7d",
            "metadata": {},
            "name": "QueueRouteReportedQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '713cba6f998d5708f9ce721230ec3f22';
export default node;
