/* tslint:disable */
/* eslint-disable */
/* @relayHash 1cae83228eb56654e582af6786d68d01 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GeneralConfigRouteQueryVariables = {};
export type GeneralConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"GeneralConfigContainer_settings">;
    };
};
export type GeneralConfigRouteQuery = {
    readonly response: GeneralConfigRouteQueryResponse;
    readonly variables: GeneralConfigRouteQueryVariables;
};



/*
query GeneralConfigRouteQuery {
  settings {
    ...GeneralConfigContainer_settings
    id
  }
}

fragment AnnouncementConfigContainer_settings on Settings {
  announcement {
    content
    duration
    createdAt
    id
  }
}

fragment DSAConfigContainer_settings on Settings {
  dsa {
    enabled
    methodOfRedress {
      method
      url
      email
    }
  }
}

fragment FlairBadgeConfigContainer_settings on Settings {
  flairBadges {
    flairBadgesEnabled
    badges {
      name
      url
    }
  }
}

fragment GeneralConfigContainer_settings on Settings {
  ...AnnouncementConfigContainer_settings
  flattenReplies
  locale
  dsa {
    enabled
    methodOfRedress {
      method
      url
      email
    }
  }
  ...DSAConfigContainer_settings
  communityGuidelines {
    enabled
    content
  }
  charCount {
    enabled
    min
    max
  }
  editCommentWindowLength
  closeCommenting {
    message
    auto
    timeout
  }
  disableCommenting {
    enabled
    message
  }
  featuredBy
  reaction {
    label
    labelActive
    sortLabel
    icon
    iconActive
  }
  badges {
    staffLabel
    adminLabel
    moderatorLabel
    memberLabel
  }
  topCommenter {
    enabled
  }
  flairBadges {
    flairBadgesEnabled
  }
  ...FlairBadgeConfigContainer_settings
  rte {
    enabled
    strikethrough
    spoiler
  }
  media {
    twitter {
      enabled
    }
    youtube {
      enabled
    }
    giphy {
      enabled
      maxRating
      key
    }
  }
  memberBios
  ...ReactionConfigContainer_settings
}

fragment ReactionConfigContainer_settings on Settings {
  reaction {
    icon
    iconActive
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
    } as any), v5 = [
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "GeneralConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "GeneralConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "GeneralConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Announcement",
                            "kind": "LinkedField",
                            "name": "announcement",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "duration",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "flattenReplies",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAConfiguration",
                            "kind": "LinkedField",
                            "name": "dsa",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAMethodOfRedressConfiguration",
                                    "kind": "LinkedField",
                                    "name": "methodOfRedress",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "method",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "email",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommunityGuidelines",
                            "kind": "LinkedField",
                            "name": "communityGuidelines",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CharCount",
                            "kind": "LinkedField",
                            "name": "charCount",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "min",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "max",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "editCommentWindowLength",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CloseCommenting",
                            "kind": "LinkedField",
                            "name": "closeCommenting",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "auto",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeout",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DisableCommenting",
                            "kind": "LinkedField",
                            "name": "disableCommenting",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featuredBy",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReactionConfiguration",
                            "kind": "LinkedField",
                            "name": "reaction",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "label",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "labelActive",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sortLabel",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "icon",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "iconActive",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "BadgeConfiguration",
                            "kind": "LinkedField",
                            "name": "badges",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "staffLabel",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "adminLabel",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "moderatorLabel",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "memberLabel",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TopCommenterConfiguration",
                            "kind": "LinkedField",
                            "name": "topCommenter",
                            "plural": false,
                            "selections": (v5 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FlairBadgeConfiguration",
                            "kind": "LinkedField",
                            "name": "flairBadges",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "flairBadgesEnabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlairBadge",
                                    "kind": "LinkedField",
                                    "name": "badges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RTEConfiguration",
                            "kind": "LinkedField",
                            "name": "rte",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "strikethrough",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "spoiler",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MediaConfiguration",
                            "kind": "LinkedField",
                            "name": "media",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TwitterMediaConfiguration",
                                    "kind": "LinkedField",
                                    "name": "twitter",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "YouTubeMediaConfiguration",
                                    "kind": "LinkedField",
                                    "name": "youtube",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GiphyMediaConfiguration",
                                    "kind": "LinkedField",
                                    "name": "giphy",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "maxRating",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "key",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "memberBios",
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "1cae83228eb56654e582af6786d68d01",
            "metadata": {},
            "name": "GeneralConfigRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '12e04d40d68e02a4e570c7ca3fbb3dd6';
export default node;
